<template>
    <div class="page-domains-register-domain container">
        <div class="row">
            <div class="col-12">
                <div class="header-info">
                    <h1 class="name">Register Domain</h1>
                </div>

                <app-tabs v-model="tab" :tabs="tabs"></app-tabs>
            </div>
        </div>

        <router-view></router-view>
    </div>
</template>

<script>
import appTabs from '@/components/app-tabs'

export default {
    components: {
        appTabs,
    },

    data() {
        return {
            tabs: [
                {
                    id: 'register',
                    name: 'domains-register-domain-register',
                    title: 'Register',
                },
                {
                    id: 'bulk-register',
                    name: 'domains-register-domain-bulk-register',
                    title: 'Bulk Register',
                },
            ],
        }
    },

    computed: {
        tab: {
            get() {
                const tab = this.tabs.find(tab => this.$route.name == tab.name || this.$route.name == tab.alias)

                if (!tab) {
                    this.tab = { name: 'domains-register-domain-register', replace: true };
                }

                return tab;
            },

            set(tab) {
                this.$router.push({
                    name: tab.name,
                    replace: true
                });
            }
        },
    },
}
</script>

<style lang="scss">
.page-domains-register-domain {
    margin-top: 24px;
    padding-bottom: 80px;

    .app-tabs {
        margin-bottom: 34px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        margin-bottom: 40px;

        .name {
            font-size: 56px;
            line-height: 62px;
            font-weight: bold;
        }
    }
}

@media (max-width: $tablet-size) {
    .page-domains-register-domain {
        .header-info {
            width: 100%;
            margin-bottom: 48px;

            .name {
                font-size: 48px;
                line-height: 56px;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-domains-register-domain {
        .header-info {
            flex-direction: column;
            margin-bottom: 32px;

            .name {
                font-size: 32px;
                line-height: 40px;
            }
        }

        .app-tabs {
            margin-bottom: 16px;
        }
    }
}
</style>